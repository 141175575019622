.inputEl {
  border-top: lightgray solid 1px;
  width: 30%;
  padding: 0.2% 0 0 0;
  margin-bottom: 12px !important;
  margin-top: 30px !important;
}
.MuiFormControlRoot {
  width: 100% !important;
}

@media (max-width: 760px) {
  .inputEl {
    width: 100%;
  }
}
