div {
  box-sizing: border-box;
}
.loadingWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
}
.searchWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.searchWrapper button {
  margin-top: 36px;
  margin-left: 1em;
  border: 0.1em solid #ffffff;
  padding: 0.6em 0.6em;
  border-radius: 0.2rem;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  font-family: inherit;
  font-size: 1em;
  transition: all 0.2s;
}
.searchWrapper button:hover {
  color: black;
  background-color: white;
}

.formLabel {
  font-size: 1.2rem;
  /* margin-left: 2rem; */
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}
.formInput {
  color: #333;
  font-size: 1.2rem;
  padding: 0.6em 0.6em;
  border-radius: 0.2rem;
  border: lightgray 1px solid;
  background-color: rgb(255, 255, 255);
  /* width: 60%; */
  display: block;
  transition: all 0.3s;
}
.formInput:placeholder-shown + .formLabel {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
.arrowUp {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(51, 51, 51, 0.6);
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(51, 51, 51, 0.6);
}
.tableContainer {
  display: block;
  margin: 2em auto;
  width: 100%;
  /* max-width: 600px; */
}

.flag-icon {
  margin-right: 0.1em;
}

.flexTable {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  border-left: solid 1px #d9d9d9;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.flexTable:first-of-type {
  border-top: solid 1px #1565c0;
  border-left: solid 1px #1565c0;
}
.flexTable:first-of-type .flexRow {
  background: #1976d2;
  color: white;
  border-color: #1565c0;
}
.flexTable.row:nth-child(odd) .flexRow {
  background: #f4f2f1;
}
.flexTable:hover {
  background: #f5f5f5;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.flexRow {
  width: calc(100% / 5);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
}

.rowspan {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}

.column {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
}
.column .flexRow {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}
.column .flexRow:hover {
  background: #f5f5f5;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.flexCell {
  width: calc(100% / 3);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

.innerWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.innerWrapper h4 {
  margin: 0;
}
.innerWrapperCountry {
  display: flex;
  justify-content: space-between;
}

.textMedium {
  font-size: 0.8em;
}
.textSmall {
  font-size: 0.6em;
}

@media all and (max-width: 767px) {
  .flexRow {
    width: calc(100% / 3);
  }
  .flexRow.first {
    width: 100%;
  }

  .column {
    width: 100%;
  }
}
@media all and (max-width: 430px) {
  .flexTable .flexRow {
    border-bottom: 0;
  }
  .flexTable .flexRow:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .header .flexRow {
    border-bottom: solid 1px;
  }

  .flexRow {
    width: 100%;
  }
  .flexRow.first {
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
  }

  .column {
    width: 100%;
  }
  .column .flexRow {
    border-bottom: solid 1px #d9d9d9;
  }

  .flexCell {
    width: 100%;
  }
}
