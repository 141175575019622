.container {
  border-left: solid lightgrey 1px;
  border-bottom: 10px solid lightgrey;
  background-color: rgba(200, 200, 200, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  padding: 1%;
  /* position: relative;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%); */
}

@media (max-width: 760px) {
  .container {
    width: 100%;
  }
}
